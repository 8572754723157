import {connect} from "react-redux";
import InputField from "../Shared/InputField";
import AddressForm from "../Cart/AddressForm";
import {useReducer, useState} from "react";
import {userSignUp} from "../../service";
import CaptchaService from "../../captcha/CaptchaService";
import {showLoading} from "../../reducers";
import CaptchaContainer from "../../captcha/CaptchaContainer";
import {useNavigate} from "react-router-dom";

function UserSignUp(props) {
    const [costumer, setCostumer] = useState({});
    const [shippingAddress, setShippingAddress] = useState({});
    const [validCostumerInfo, setValidCostumerInfo] = useState(false);
    const [validShippingAddress, setValidShippingAddress] = useState(false);
    const [count, forceUpdate] = useReducer(x => x + 1, 0);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [validHuman, setValidHuman] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (input) => {
        costumer[input.id] = input.value.trim();
        setCostumer(costumer);
        validateRequired();
        forceUpdate();
    }

    const handleSuccess = () => {
        setCostumer({});
        setShippingAddress({});
        forceUpdate();
        alert("Epost Sent");
        navigate("/shop/category/all")
    }

    function validateRequired() {
        if (costumer.firstName && costumer.firstName !== ""
            && costumer.lastName && costumer.lastName !== ""
            && costumer.email && costumer.email !== ""
            && costumer.phone && costumer.phone !== "") {
            setValidCostumerInfo(true)
            if (validShippingAddress)
                setAllowSubmit(true)
        } else {
            setValidCostumerInfo(false);
            setAllowSubmit(false);
        }
    }

    function handleAddressChange(address) {
        if (address.address1 !== "" && address.postalArea !== "" && address.postalCode !== "") {
            setValidShippingAddress(true)
            if (validCostumerInfo)
                setAllowSubmit(true);
        } else {
            setValidShippingAddress(false);
            setAllowSubmit(false);
        }
        setShippingAddress(address);
    }

    return (

        <form className="container max-w-4xl mx-auto" id={`signupform`}>
            <h1 className={"py-2 font-medium text-yellow text-2xl text-center"}>Registrer deg</h1>
            <div className="grid grid-cols-12 gap-y-6 gap-x-4">

                <div className="col-span-full">
                    <InputField id={"firstName"} label={"Navn"}
                                value={costumer.firstName ? costumer.firstName : ""} type={"text"}
                                autocomplete={"first-name"} required={true}
                                handleChange={handleInputChange}/>
                </div>

                <div className="col-span-full">
                    <InputField id={"lastName"} label={"Etternavn"} type={"text"}
                                value={costumer.lastName ? costumer.lastName : ""}
                                autocomplete={"family-name"} required={true}
                                handleChange={handleInputChange}/>
                </div>
                <div className="col-span-full">
                    <InputField id={"email"} label={"Epost"} type={"email"}
                                autocomplete={"email"}
                                value={costumer.email ? costumer.email : ""}
                                required={true} handleChange={handleInputChange}/>
                </div>

                <div className="col-span-full">
                    <InputField id={"phone"} label={"Mobil"} type={"tel"} autocomplete={"phone"}
                                value={costumer.phone ? costumer.phone : ""}
                                required={true} handleChange={handleInputChange}/>
                </div>
            </div>
            <div className={"mt-10"}>
                <h3 className="text-lg font-medium text-gray-900">Adresse</h3>
                <div className={"grid grid-cols-12 gap-y-6 gap-x-4 "}>
                    <AddressForm key={`shipping-address`} handleAddressChange={handleAddressChange}
                                 current={shippingAddress}/>
                </div>
            </div>
            <div className={"col-span-full mt-4"}>
                <button
                    disabled={!allowSubmit || !validHuman}
                    onClick={(e) => {
                        setAllowSubmit(false);
                        e.preventDefault();
                        props.showLoading(true)
                        let form = new FormData();

                        form.append("firstName", costumer.firstName);
                        form.append("lastName", costumer.lastName);
                        form.append("email", costumer.email);
                        form.append("phone", costumer.phone);
                        form.append("address1", shippingAddress.address1);
                        form.append("address2", shippingAddress.address2);
                        form.append("postalCode", shippingAddress.postalCode);
                        form.append("postalArea", shippingAddress.postalArea);
                        form.append("city", shippingAddress.city);
                        form.append("country", "");
                        form.append("redirectUrl", "/shop/signup");

                        CaptchaService.getToken().subscribe(captchaToken => {
                            props.showLoading(true);
                            form.append("challenge", captchaToken.challenge);
                            form.append("nonce", captchaToken.nonce);
                            userSignUp(form).then(response => {
                                props.showLoading(false)
                                if (response.status && response.status !== 200) {
                                    alert("error")
                                    setValidHuman(false);
                                } else
                                    handleSuccess();
                                setAllowSubmit(true)
                            }).catch(error => {
                                console.error(error);
                                props.showLoading(false);
                                setValidHuman(false);
                                setAllowSubmit(true)
                            })

                        });
                    }}
                    className="default-button w-full max-w-full disabled:bg-gray-100 disabled:pointer-events-none"
                >
                    Melde deg på
                </button>
            </div>
            <CaptchaContainer validHuman={validHuman} service={CaptchaService} onToken={(bool) => {
                setValidHuman(bool)
            }}/>
        </form>
    )
}

const mapStateToProps = () => {
    return {}
}
export default connect(mapStateToProps, {showLoading})(UserSignUp);