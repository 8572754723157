import {connect} from "react-redux";
import {useEffect, useState} from "react";
import InputField from "../Shared/InputField";
import {sendContactUsEmailService} from "../../service";
import {showLoading} from "../../reducers";
import CaptchaContainer from "../../captcha/CaptchaContainer";
import CaptchaService from "../../captcha/CaptchaService";

function GetQuotation(props) {
    const [productCode, setProductCode] = useState(props.productForQuote);
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [organizationNumber, setOrganizationNumber] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState("");
    const [text, setText] = useState("");
    const [validHuman,setValidHuman] = useState(false);
    const [valid,setValid] = useState(false);
    useEffect(()=>{
        if (props.loggedInUser.id){
            setName(props.loggedInUser.firstName);
            setLastName(props.loggedInUser.lastName);
            setEmail(props.loggedInUser.email);
            setPhone(props.loggedInUser.phone);
        }
    },[props.loggedInUser])

    useEffect(()=>{
        if (validHuman && productCode.trim().length>0 && name.trim().length>0 && lastName.trim().length>0 && email.trim().length>0 && phone.trim().length>0 )
            setValid(true);
        else
            setValid(false);
    },[validHuman,productCode,name,lastName,email,phone,companyName,organizationNumber,subject,text])

    const handleChange = (target) => {
      switch (target.id) {
          case "firstName":
              setName(target.value);
              break;
          case "lastName":
              setLastName(target.value);
              break;
          case "phone":
              setPhone(target.value);
              break;
          case "email":
              setEmail(target.value);
              break;
          case "companyName":
              setCompanyName(target.value);
              break;
          case "organizationNumber":
              setOrganizationNumber(target.value);
              break;
          case "productCode":
              setProductCode(target.value);
              break;
          case "subject":
              setSubject(target.value);
              break;
          default:
              return;
      }
    }

     const handleSuccess = ()=>{
        setSubject("");
        setText("");
        alert("Sent");
    }

    return (<main className="container max-w-3xl mx-auto animate__animated animate__backInLeft">
            <form className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div><InputField id={"firstName"} type={"text"} label={"Ditt Navn"} required={true} handleChange={handleChange}
                                 autocomplete={"first-name"} value={name}/></div>
                <div>
                    <InputField id={"lastName"} type={"text"} label={"Etternanv"} required={true} handleChange={handleChange}
                                autocomplete={"last-name"} value={lastName}/>
                </div>
                <div className="sm:col-span-2">
                    <InputField id={"email"} type={"email"} label={"Epost"} required={true}
                                autocomplete={"email"}
                                handleChange={handleChange}
                                value={email}/>
                </div>
                <div className="sm:col-span-2">
                    <InputField id={"companyName"} type={"text"} label={"Firmanavn"} required={false} handleChange={handleChange}
                                autocomplete={"company-name"} value={companyName}/>
                </div>
                <div className="sm:col-span-2">
                    <InputField id={"organizationNumber"} type={"text"} label={"Org. Nummer"} required={false} handleChange={handleChange}
                                autocomplete={"organization-number"} value={organizationNumber}/>
                </div>
                <div className="sm:col-span-2">
                    <InputField id={"productCode"} type={"text"} label={"Produkt kode"} required={true} handleChange={handleChange}
                                autocomplete={"product-kode"} value={productCode}/>
                </div>
                <div className="sm:col-span-2">
                    <InputField id={"phone"} type={"text"} label={"Telefon"} required={true} autocomplete={"mobile"} handleChange={handleChange}
                                value={phone}/>
                </div>
                <div className="sm:col-span-2">
                    <InputField id={"subject"} type={"text"} label={"Subjekt"} required={false} autocomplete={"subject"} handleChange={handleChange}
                                value={subject}/>
                </div>
                <div className="sm:col-span-2">
                    <div className="flex justify-between">
                        <label htmlFor="how-can-we-help" className="block text-sm font-medium text-gray-700">Beskriv
                            hva vi kan hjelpe deg med</label>
                        <span id="how-can-we-help-description"
                              className="text-sm text-gray-500">Max. 500 karakterer</span>
                    </div>
                    <div className="mt-1">
                       <textarea id="text" name="how-can-we-help" onChange={(e)=>setText(e.target.value)} defaultValue={text}
                                 aria-describedby="how-can-we-help-description" rows="4"
                                 className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-yellow focus:ring-yellow sm:text-sm"></textarea>
                    </div>
                </div>
                <div className="text-right sm:col-span-2">
                    <button type="submit" disabled={!valid} className="default-button" onClick={(e)=>{
                        e.preventDefault();
                        let form = new FormData();
                        form.append("firstName",name);
                        form.append("lastName",lastName);
                        form.append("phone",phone);
                        form.append("email",email);
                        form.append("productCode",productCode);
                        form.append("companyName",companyName);
                        form.append("organizationNumber",organizationNumber);
                        form.append("subject",subject);
                        form.append("text",text);
                        form.append("redirectUrl","/shop/getquote");
                        CaptchaService.getToken().subscribe(captchaToken => {
                            console.log("tokena", captchaToken)
                            props.showLoading(true);
                            form.append("challenge",captchaToken.challenge);
                            form.append("nonce",captchaToken.nonce);
                            sendContactUsEmailService(form).then(response => {
                                props.showLoading(false)
                                setValidHuman(false);
                                handleSuccess();
                            }).catch(error => {
                                console.error(error);
                                props.showLoading(false);
                                setValidHuman(false);
                            })

                        });

                    }}
                    >Send</button>
                </div>
                <CaptchaContainer validHuman={validHuman} service={CaptchaService} onToken={(bool)=>{setValidHuman(bool)}}/>
            </form>
        </main>
    )
}

const mapStateToProps = ({loggedInUser,productForQuote}) => {
    return {loggedInUser: loggedInUser,productForQuote:productForQuote}
}
export default connect(mapStateToProps, {showLoading})(GetQuotation);