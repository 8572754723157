import {Link, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {
    confirmB2BOrderService,
    getOrderService,
    getShippingPriceService,
    updateShippingMethodInOrderService
} from "../../service";
import ImageThumbnail from "../Shared/ImageThumbnail";
import DeliveryMethods from "../Cart/DeliveryMethods";
import {calculateVAT, toFixedNumber, toLocalDateFormat} from "../../utils";
import {connect} from "react-redux";
import {showLoading} from "../../reducers";
import {LockClosedIcon} from "@heroicons/react/20/solid";
import CaptchaService from "../../captcha/CaptchaService";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function OrderDetails(props) {

    const params = useParams();
    const [deliveryMethods,setDeliveryMethods] = useState([]);
    const [selectedDeliveryMethod,setSelectedDeliveryMethod] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [updated, setUpdated] = useState(0);

    const {order} = useFetchOrder(params.orderId,updated,CaptchaService,props.showLoading);

    function getOrderStatusStep(status) {
        switch (status) {
            case "CREATED":
                return 0;
            case "RECEIVED":
                return 1;
            case "PACKED":
                return 2;
            case "SHIPPED":
                return 3;
            case "DELIVERED":
                return 4;
            default:
                return 0
        }
    }
    const formRef = useRef();
    useEffect(()=>{
        if (order && !order.paid && !order.postedToErp){
            let form = formRef.current;
            if (form)
                form.scrollIntoView({inline:"center",behavior:"smooth"});
            props.showLoading(true);
            getShippingPriceService(order.id).then(response=>{
                console.log("res",response);
                if (response.status && response.stat!==200)
                    console.log("error")
                else
                    setDeliveryMethods(response)
                props.showLoading(false);
            }).catch(error=>{
                console.error("could not fetch shipping charges",error)
                props.showLoading(false);
            })
        }
        if (order && searchParams.get("transaction") && searchParams.get("error"))
            alert("Transaction Failed:"+searchParams.get("error"))
    },[order,params.orderId])

    const getTotal = (total) => {
      if (selectedDeliveryMethod.id)
          return (parseFloat(total) + parseFloat(selectedDeliveryMethod.amountWithVat)).toFixed(2)
        else return Number(total).toLocaleString("nb");
    }

    const handleDeliveryMethodSelect = (deliveryMethod) => {
          props.showLoading(true);
      updateShippingMethodInOrderService(order.id,deliveryMethod).then(response=>{
          if (response.status && response.status!==200){
              console.error("error in updating order")
          props.showLoading(false);
          }else {
              setSelectedDeliveryMethod(deliveryMethod);
          props.showLoading(false);
          }
      }).catch(error=>{
          props.showLoading(false);
          console.error("error",error);
      })
    }
    function isExtraDeliveryTimeRequired(){
        let required = false;
        if (order){
            order.orderLines.forEach(line=>{
                if (!line.product.stockControlled)
                    required = true;
            })
        }
        return required;
    }
if (order)
    return (
        <div className="bg-gray-50">
            <div className="mx-auto max-w-2xl pt-16 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="space-y-2 px-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
                    <div className="flex sm:items-baseline sm:space-x-4">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Ordre
                            #{order.orderNumber}</h1>
                        {(order.orderStatus==="SHIPPED" || order.orderStatus==="DELIVERED") &&
                        <span className="before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-yellow relative inline-block">
                            <span className="relative text-white">
                                <a href={`https://www.consignorportal.com/ui/sv/s?pp=1d6a3666-ef4b-44b6-b438-1377c890930d&q=${order.erpOrderId}`}
                                   className="hidden text-md font-medium text-white hover:text-black sm:block">Sjekk sporing <span aria-hidden="true"> &rarr;</span>
                                </a>
                            </span>
                        </span>}
                    </div>
                    <p className="text-sm text-gray-600">
                        Ordredato{' '}
                        <time dateTime={order.created} className="font-medium text-gray-900 capitalize">
                            {toLocalDateFormat(order.created)}
                        </time>
                    </p>
                    {(order.orderStatus==="SHIPPED" || order.orderStatus==="DELIVERED") &&
                        <a href={`https://www.consignorportal.com/ui/sv/s?pp=1d6a3666-ef4b-44b6-b438-1377c890930d&q=${order.erpOrderId}`}
                           className="text-sm font-medium text-indigo-600 hover:text-indigo-500 sm:hidden">
                            Sjekk sporing
                            <span aria-hidden="true"> &rarr;</span>
                        </a>
                    }
                </div>

                {/* Products */}
                <div className="mt-6">
                    <h2 className="sr-only">Products purchased</h2>

                    <div className="space-y-8">
                        {order.orderLines.map((orderLine) => (
                            <div
                                key={orderLine.product.id}
                                className="border-t border-b border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
                            >
                                <div className="py-6 px-4 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                                    <div className="sm:flex lg:col-span-7">
                                        <div className="aspect-w-1 aspect-h-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-40 sm:w-40">
                                            {orderLine.product.productImages.length>0&&<ImageThumbnail image={orderLine.product.productImages[0]}/>}
                                        </div>

                                        <div className="mt-6 sm:mt-0 sm:ml-6">
                                            <h3 className="text-base font-medium text-gray-900">
                                                <Link to={`/shop/product/${orderLine.product.id}`}>{orderLine.product.name}</Link>
                                            </h3>
                                            {orderLine.discountedPrice>0&&
                                                <p className="mt-2 text-sm font-medium text-red-500">NOK {orderLine.discountedPrice}</p>}
                                            <p className={`mt-2 text-sm font-medium text-gray-900 ${orderLine.discountedPrice>0?"line-through":""}`}>kr {toFixedNumber(orderLine.product.price,2)}</p>
                                            <p className="mt-2 text-sm font-medium text-gray-900">Ant. {orderLine.quantity} <span className={"text-sm text-gray-600 font-medium"}>(Totalt: kr {toFixedNumber(orderLine.totalAmount,2)})</span></p>
                                            <p className="mt-3 text-sm text-gray-500">{orderLine.product.description}</p>
                                        </div>
                                    </div>

                                    <div className="mt-6 lg:col-span-5 lg:mt-0">
                                        <dl className="grid grid-cols-2 gap-x-6 text-sm">
                                            <div>
                                                <dt className="font-medium text-gray-900">Leveringsadresse</dt>
                                                <dd className="mt-3 text-gray-500">
                                                    <span className="block">{order.shippingAddress.address1}</span>
                                                    <span className="block">{order.shippingAddress.postalCode} {order.shippingAddress.postalArea}</span>
                                                    <span className="block">{order.shippingAddress.city}</span>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>

                                <div className="border-t border-gray-200 py-6 px-4 sm:px-6 lg:p-8">
                                    <h4 className="sr-only">Status</h4>
                                    <p className="text-sm font-medium text-gray-900 capitalize">
                                       <time dateTime={order.updated}>{toLocalDateFormat(order.updated)}</time>
                                    </p>
                                    <div className="mt-6" aria-hidden="true">
                                        <div className="overflow-hidden rounded-full bg-gray-200">
                                            <div
                                                className="h-2 rounded-full bg-yellow"
                                                style={{ width: `calc((${getOrderStatusStep(orderLine.orderStatus)} * 2 + 1) / 8 * 100%)` }}
                                            />
                                        </div>
                                        <div
                                            className={"text-yellow sm:font-medium text-xs sm:hidden"}>{orderLine.orderStatus}</div>
                                        <div
                                            className="mt-6 hidden grid-cols-5 sm:text-sm sm:font-medium text-gray-600 sm:grid">
                                            <div className="text-yellow">Ikke bekreftet</div>
                                            <div
                                                className={classNames((orderLine.orderStatus === "RECEIVED" || orderLine.orderStatus === "PACKED" || orderLine.orderStatus === "SHIPPED" || orderLine.orderStatus === "DELIVERED") ? 'text-yellow' : '', 'text-center')}>
                                                Bekreftet
                                            </div>
                                            <div
                                                className={classNames((orderLine.orderStatus === "PACKED" || orderLine.orderStatus === "SHIPPED" || orderLine.orderStatus === "DELIVERED") ? 'text-yellow' : '', 'text-center')}>
                                                Pakk
                                            </div>
                                            <div
                                                className={classNames((orderLine.orderStatus === "SHIPPED" || orderLine.orderStatus === "DELIVERED") ? 'text-yellow' : '', 'text-center')}>
                                                Utsendt
                                            </div>
                                            <div
                                                className={classNames(orderLine.orderStatus === "DELIVERED" ? 'text-yellow' : '', 'text-right')}>
                                                Levert
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Billing */}
                {order.orderStatus !=="CREATED" && <div className="mt-16">
                    <h2 className="sr-only">Faktureringssammendrag</h2>

                    <div
                        className="bg-gray-100 py-6 px-4 sm:rounded-lg sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8 lg:py-8">
                        <dl className="grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7">
                            <div>
                                <dt className="font-medium text-gray-900">Fakturaadresse</dt>
                                <dd className="mt-3 text-gray-500">
                                    <span className="block">{order.billingAddress.address1}</span>
                                    <span
                                        className="block">{order.billingAddress.postalCode} {order.billingAddress.postalArea}</span>
                                    <span className="block">{order.billingAddress.city}</span>
                                </dd>
                            </div>
                            {order.orderType==="B2C" &&<div>
                                <dt className="font-medium text-gray-900">Betalingsinformasjon</dt>
                                <dd className="-ml-4 -mt-1 flex flex-wrap">
                                    <div className="ml-4 mt-4 flex-shrink-0">
                                        <svg
                                            aria-hidden="true"
                                            width={36}
                                            height={24}
                                            viewBox="0 0 36 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-auto"
                                        >
                                            <rect width={36} height={24} rx={4} fill="#224DBA"/>
                                            <path
                                                d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                                                fill="#fff"
                                            />
                                        </svg>
                                        <p className="sr-only">Visa</p>
                                    </div>
                                    <div className="ml-4 mt-4">
                                        <p className="text-gray-900">Slutter
                                            med {order.cardInformation.maskedPan.substring(order.cardInformation.maskedPan.length - 4)}</p>
                                        <p className="text-gray-600">Expires {order.cardInformation.expiryDate.substring(4, 2)} / {order.cardInformation.expiryDate.substring(0, 2)}</p>
                                    </div>
                                </dd>
                            </div>}
                        </dl>

                        <dl className="mt-8 divide-y divide-gray-200 text-sm lg:col-span-5 lg:mt-0">
                            <div className="flex items-center justify-between pb-4">
                                <dt className="text-gray-600">Delsum</dt>
                                <dd className="font-medium text-gray-900">NOK {toFixedNumber(order.subtotal + order.discount,2)}</dd>
                            </div>
                            {order.discount>0&&<div className="flex items-center justify-between py-4">
                                <dt className="text-gray-600">Rabatt</dt>
                                <dd className="font-medium text-gray-900">{-toFixedNumber(order.discount, 2)}</dd>
                            </div>}
                            <div className="flex items-center justify-between py-4">
                                <dt className="text-gray-600">Fraktkostnader</dt>
                                <dd className="font-medium text-gray-900">{toFixedNumber(order.shippingCharges,2)}</dd>
                            </div>
                            <div className="flex items-center justify-between py-4">
                                <dt className="text-gray-600">MVA.</dt>
                                <dd className="font-medium text-gray-900">25% ({calculateVAT(order.subtotal)})</dd>
                            </div>
                            <div className="flex items-center justify-between py-4">
                                <dt className="text-gray-600">Totalt</dt>
                                <dd className="font-medium text-gray-900">NOK {toFixedNumber(order.total+order.shippingCharges,2)}</dd>
                            </div>
                            {order.orderType==="B2C"&&<div className="flex items-center justify-between pt-4">
                                <dt className="font-medium text-gray-900">Bestilling totalt betalt</dt>
                                <dd className="font-medium text-yellow">NOK {toFixedNumber(order.totalPaid,2)}</dd>
                            </div>}
                        </dl>
                    </div>
                </div>}
                {order.orderStatus ==="CREATED" && <div className={"mt-16"}>
                    <DeliveryMethods isExtraDeliveryTimeRequired={isExtraDeliveryTimeRequired()} deliveryMethods={deliveryMethods} selectedDeliveryMethod={selectedDeliveryMethod} setSelectedDeliveryMethod={handleDeliveryMethodSelect}/>
                    {order.orderType==="B2C"&&<form ref={formRef} id={"payment-form"} action="/webshop/nets/pay" method={"POST"}>
                        <input type="hidden" id={"orderId"} name={"orderId"} value={order.id}/>
                        <input type="hidden" id={"orderNumber"} name={"orderNumber"} value={order.orderNumber}/>
                        <input type="hidden" id={"customerId"} name={"customerId"} value={order.customerId}/>
                        <input type="hidden" id={"description"} name={"description"}
                               value={`Ammount paid for OrderNr ${order.orderNumber}`}/>
                        <input type="hidden" id={"redirectURL"} name={"redirectURL"}
                               value={`${process.env.REACT_APP_HOME}/shop/orders/${order.id}`}/>
                        <button type={"submit"} disabled={Object.keys(selectedDeliveryMethod).length === 0}
                                className="default-button w-full max-w-full disabled:bg-gray-100 disabled:pointer-events-none">Bekreft
                            og Betal ( Kr {getTotal(order.total)} <span className={"text-gray-500 text-xs px-2"}> ink. mva </span>)
                        </button>
                        <div className={"w-full"}>
                            <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                                <LockClosedIcon className="mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                Ved å klikke 'Betal' bekrefter du automatisk våre kjøpsvilkår.
                            </p>
                        </div>
                    </form>}
                    {order.orderType === "B2B" &&
                        <div>
                            <input type="hidden" id={"customerId"} name={"customerId"} value={order.customerId}/>

                            <button type={"submit"} disabled={Object.keys(selectedDeliveryMethod).length === 0}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.showLoading(true)
                                        confirmB2BOrderService(order.id,props.userInfo.id).then(response=>{
                                            if (response.status && response.status!==200)
                                                console.error("could not confirm b2b order");
                                            else {
                                                setUpdated(new Date().getMilliseconds())
                                            }
                                            props.showLoading(false)
                                        }).catch(error =>{
                                            console.error("could not confirm b2b order",error);
                                            props.showLoading(false)
                                        })
                                    }}
                                    className="default-button w-full max-w-full disabled:bg-gray-100 disabled:pointer-events-none">
                                Bekreft ( Kr {getTotal(order.total)} )
                            </button>
                            <div className={"w-full"}>
                                <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                                    <LockClosedIcon className="mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    Ved å klikke 'Bekreft' bekrefter du automatisk våre kjøpsvilkår.
                                </p>
                            </div>
                        </div>}
                </div>}
            </div>
        </div>
    )
}

const mapStateToProps = ({userInfo}) => {
  return {userInfo:userInfo}
}

export default connect(mapStateToProps,{showLoading})(OrderDetails)

const useFetchOrder = (orderId,updated,service,showLoading) => {
    const [order, setOrder] = useState(null);
        useEffect(() => {
            showLoading(true);
            service.getHeadlessToken().subscribe(captchaToken => {
                showLoading(true);
                getOrderService(orderId,captchaToken).then(order => {
                    if (order)
                        setOrder(order)
                    showLoading(false);
                }).catch(err => {
                    console.error("error", err)
                    showLoading(false)
                })
            });
        }, [orderId, updated])
    return {order}
}