import React, {useEffect, useState} from "react";
import Home from "./components/Home/Home";
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {fetchCatalog, reloadCart} from "./reducers";
import CatalogView from "./components/Catalog/CatalogView";
import Header from "./components/Header/Header";
import ProductDetails from "./components/Product/ProductDetailsView";
import CartDetails from "./components/Cart/CartDetails";
import CatalogNodeView from "./components/Catalog/CatalogNodeView";
import CheckoutForm from "./components/Cart/CheckoutForm";
import MyPage from "./components/Customer/MyPage";
import OrderDetails from "./components/Customer/OrderDetails";
import {getCachedCartFromLocalStorage} from "./utils";
import Loading from "./components/Shared/Loading";
import GetQuotation from "./components/EmailForms/GetQuotation";
import {fetchCatalogService} from "./service";
import UserSignUp from "./components/Login/UserSignUp";

//TODO : fix tailwind containers

const shopId = process.env.REACT_APP_WEBSHOP;


function App(props) {

    const [catalogId, setCatalogId] = useState("");

    useEffect(()=>{
        fetchCatalogService(shopId).then(response => {
            if (response.status && response.status !== 200)
                console.log("error")
            else {
                props.fetchCatalog(response)
                setCatalogId(response.id)
            }
        }).catch(err => {
            console.error("error fetching catalog", err);
        })
    },[shopId])

    useEffect(() => {
        if (catalogId!==""){
            const cachedCart = getCachedCartFromLocalStorage();
            if (cachedCart.length > 0)
                props.reloadCart(cachedCart);
        }
    }, [catalogId])
    if (props.init)
        return (
            <Router basename={process.env.REACT_APP_BASENAME}>
                <Loading/>
                <Header catalogId={catalogId}/>
                <div className={"flex flex-grow flex-col relative p-4 md:py-10"}>
                    <Routes>
                        <Route exact path={"/"} element={<Home/>}/>
                        <Route path={"/shop"} element={<Home/>}/>
                        <Route path={"/shop/category/all"} element={<CatalogView/>}/>
                        <Route path={"/shop/category/:catalogNodeId"} element={<CatalogNodeView/>}/>
                        <Route path={"/shop/product/:productId"} element={<ProductDetails/>}/>
                        <Route path={"/shop/cart"} element={<CartDetails popularProducts={props.popularProducts}/>}/>
                        <Route path={"/shop/checkout"} element={<CheckoutForm/>}/>
                        <Route path={"/shop/mypage/:customerId"} element={<MyPage/>}/>
                        <Route path={"/shop/orders/:orderId"} element={<OrderDetails/>}/>
                        <Route path={"/shop/getquote"} element={<GetQuotation/>}/>
                        <Route path={"/shop/signup"} element={<UserSignUp/>}/>
                    </Routes>
                </div>
            </Router>
        )
    return (
        <div className={"fixed flex justify-center items-center z-50 w-full h-full bg-gray-400 bg-opacity-25"}>
                <span
                    className="animate-spin relative inline-flex rounded-full h-8 w-8 border-2 border-t-sky-600"></span>
            <span className={"ml-2 text-white text-center animate-pulse"}>loading...</span>
        </div>
    )
}


const mapStateToProps = ({init}) => {
    return {init: init}
}
export default connect(mapStateToProps, {fetchCatalog, reloadCart})(App);
