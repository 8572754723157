import {useEffect, useState} from 'react'
import {MinusIcon, PlusIcon} from '@heroicons/react/24/outline'
import {connect} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {fetchProductDetailsService} from "../../service";
import {addProductToCart,setProductForQuote} from "../../reducers";
import ProductVariant from "./ProductVariant";
import ImageTabs from "./ImageTabs";
import ProductAdditionalDetailsView from "./ProductAdditionalDetailsView";
import ProductAttachments from "./ProductAttachments";
import BackButton from "../Shared/BackButton";
import {convertNumberToLocale, getTotalWithVAT, isAuthorized} from "../../utils";

function getDatafieldFromProductCard(datafieldId,productCards) {
    let field = null;
    productCards.forEach(productCard=>{
        if (field==null){
            productCard.datafieldList.forEach(datafield=>{
                if (field===null && datafield.id===datafieldId)
                    field=datafield;
            });
            if (field===null)
                productCard.datafieldGroupingList.forEach(grouping=>{
                    grouping.datafieldList.forEach(datafield=>{
                        if (datafield.id===datafieldId)
                            field = datafield;
                    })
                })
        }
    })
    return field;
}

function getVariantName(product) {
    let variantName = [];
    if (product.groupVariantsOnDatafield)
        product.groupVariantsOnDatafield.forEach(id => {
            let value = getDatafieldFromProductCard(id, product.productCards);
            if (value)
                variantName.push(value.value.join(' '))
        })
    return variantName.join(" - ")
}
function ProductDetailsView(props) {

    function showPriceForB2C(product,crmPerson) {
        if (product.hidePriceForB2C && crmPerson && crmPerson.customerType === "B2B")
            return true;
        if (product.hidePriceForB2C && Object.keys(crmPerson).length === 0)
            return false;
        return !product.hidePriceForB2C;

    }

    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [quantity,setQuantity] = useState(1);
    const [showPriceWithMva, setShowPriceWithMva] = useState(false);
    const params = useParams();
    const productId = params.productId;
    const {productDetails} = useFetchProductDetails(productId,props.userInfo,props.crmPerson);
    const [selectedProduct,setSelectedProduct] = useState(null);

    useEffect(() => {
        if (productDetails.id && productDetails.variants && productDetails.variants.length>0) {
            if (searchParams.get("variant"))
                productDetails.variants.forEach(variant => {
                    if (variant.no.replaceAll(" ", "") === searchParams.get("variant").replaceAll(" ", ""))
                        setSelectedProduct(variant);
                })
            else
                setSelectedProduct(productDetails.variants[0])
        }
        if (productDetails.id && productDetails.variants.length===0)
            setSelectedProduct(productDetails)
    },[productId,productDetails])


    function alterSelectedVariant(product) {
        searchParams.set("variant",product.no);
        setSearchParams(searchParams);
        setSelectedProduct(product);
    }

    function getProductVariants(products) {
        let variants = [];
        products.filter(product => product.id !== selectedProduct.id).forEach(product => {

                variants.push(<div key={product.id} className={"flex flex-col gap-1 border rounded px-2"}>
                    <span className={"font-medium"}>{getVariantName(product)}</span>
                    <ProductVariant key={product.id} setSelectedProduct={alterSelectedVariant} product={product}/>
                </div>)
            }
        )
        return <div className={"flex flex-col gap-2"}>
            <div className={"text-2xl font-bold tracking-tight text-gray-900"}>Variants</div>
            <div className={"grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8"}>
                {variants}
            </div>
        </div>
    }

    function getVariantsFilter() {
        let variantsFilters = [];
        if (productDetails.variants && productDetails.variants.length>0 && productDetails.groupVariantsOnDatafield)
            productDetails.groupVariantsOnDatafield.forEach(id=> {
                let datafield = getDatafieldFromProductCard(id,productDetails.productCards);
                variantsFilters.push(<div className={"p-2 rounded shadow"}>{datafield.name}</div>)
            });
        return variantsFilters;
    }

    if (selectedProduct && selectedProduct.id) {


        return (<div className="bg-white">
            <div className={"max-w-7xl mx-auto"}><BackButton/></div>
            <div className="mx-auto max-w-2xl py-8 px-4 sm:px-2 lg:max-w-7xl">
                <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
                    {/* Image gallery */}
                    <div className={"p-4 bg-gray-100 rounded"}>
                        <ImageTabs images={selectedProduct.productImages}/>
                    </div>

                    {/* Product info */}
                    <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900">{selectedProduct.name} {(selectedProduct.groupVariantsOnDatafield !== null && selectedProduct.groupVariantsOnDatafield.length>0) &&
                            <span className={"text-gray-500 text-sm"}>({getVariantName(selectedProduct)})</span>}</h1>

                        <div className="mt-3">
                            <h2 className="sr-only">Product information</h2>
                            {selectedProduct.purchaseAbleOnWeb && showPriceForB2C(selectedProduct,props.crmPerson) && selectedProduct.price>0 && <div className={"flex flex-col"}>
                                {selectedProduct.discountedPrice>0 && <div className={"flex text-lg font-medium text-red-500"}>kr {convertNumberToLocale(selectedProduct.discountedPrice)},- eks mva </div>}
                                <p className="tracking-tight text-gray-900 font-medium">
                                    <span className={`text-gray-900 text-base font-bold ${selectedProduct.discountedPrice>0?"line-through":""}`}>Kr {convertNumberToLocale(selectedProduct.price)},- eks mva </span>
                                    <span className={"text-gray-500 text-xs underline p-2 hover:text-yellow cursor-pointer"} onClick={()=>setShowPriceWithMva(!showPriceWithMva)}>Vis med MVA</span>
                                </p>
                                {showPriceWithMva && <p className="tracking-tight text-gray-900 font-medium">
                                    <span className={"text-gray-700 text-base font-bold"}>Kr {getTotalWithVAT(selectedProduct.discountedPrice>0?selectedProduct.discountedPrice:selectedProduct.price)},- med mva </span>
                                </p>}
                            </div>}
                            <p className="tracking-tight text-gray-900 font-medium"><span
                                className={"text-gray-400 text-base"}>SKU </span>{selectedProduct.no}</p>
                            <p className={"text-gray-900 text-base font-medium"}>
                                {(selectedProduct.stock > 0 && selectedProduct.stockControlled && selectedProduct.price>0) ?
                                    <span className={"text-gray-400 text-base"}>På lager: <span>✅</span></span> :
                                    <span className={"text-gray-400 text-base"}>Ikke på lager: <span>❌</span></span>}
                                {((selectedProduct.stock < 1 && selectedProduct.stockControlled!==null && !selectedProduct.stockControlled) || selectedProduct.price === 0) &&
                                    <p className="mt-1 text-sm text-white flex"><span
                                        className={"bg-blue-500 p-2 rounded"}>Bestillingsvare</span>
                                    </p>}
                            </p>
                            {props.userInfo.admin && <p className={"text-gray-900 text-base font-medium"}>
                                <span className={"text-gray-400 text-base"}>På lager:</span> {productDetails.stock}
                                <br/>
                                <span className={"text-gray-400 text-base"}>Lagerplassering:</span> {productDetails.stockLocation}
                            </p>}
                        </div>


                        <div className="mt-6">
                            <h3 className="sr-only">Description</h3>
                            <div
                                className="space-y-6 text-base text-gray-700"
                                dangerouslySetInnerHTML={{__html: selectedProduct.description}}
                            />
                        </div>
                        <div className="mt-6 border-t">
                            <h3 className="sr-only">Description</h3>
                            <div
                                className="space-y-6 text-base text-gray-700"
                                dangerouslySetInnerHTML={{__html: selectedProduct.descriptionLong}}
                            />
                        </div>

                        {(selectedProduct.purchaseAbleOnWeb && showPriceForB2C(selectedProduct,props.crmPerson) && (selectedProduct.stock > 0 || !selectedProduct.stockControlled) && selectedProduct.price>0) &&
                            <div className="mt-6">
                                <div className={"w-28"}>
                                    <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">
                                        Ant.
                                    </label>
                                    <div className="relative mt-1 rounded-md shadow-sm">
                                        <div
                                            className="absolute inset-y-0 left-0 flex items-center justify-center px-2 border-r cursor-pointer"
                                            onClick={() => quantity > 0 && setQuantity(quantity - 1)}>
                                        <span className="text-gray-500 sm:text-sm"><MinusIcon
                                            className={"h-4 w-4"}/></span>
                                        </div>
                                        <input
                                            type="number"
                                            name="quantity"
                                            id="quantity"
                                            className="block w-full rounded-md border-gray-300 px-10 text-center focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                                            value={quantity}
                                            onChange={(e) => setQuantity(Number(e.target.value))}
                                            aria-describedby="price-currency"
                                        />
                                        <div
                                            className="absolute inset-y-0 right-0 flex items-center justify-center px-2 border-l cursor-pointer"
                                            onClick={() => (quantity < selectedProduct.stock || !selectedProduct.stockControlled) && setQuantity(quantity + 1)}>
                                        <span className="text-gray-500 sm:text-sm" id="price-currency"><PlusIcon
                                            className={"h-4 w-4"}/></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:flex-col1 mt-10 flex">
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.addProductToCart(selectedProduct, quantity)
                                        }}
                                        type="submit"
                                        className={"default-button"}
                                    >
                                        Legg i Handlekurven
                                    </button>

                                </div>
                            </div>
                        }
                        {(!selectedProduct.purchaseAbleOnWeb || !showPriceForB2C(selectedProduct,props.crmPerson) || selectedProduct.price===0)  && <div className={"mt-2"}>
                            <div className="sm:flex-col1 mt-10 flex">
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.setProductForQuote(selectedProduct.no)
                                        navigate("/shop/getquote");
                                    }}
                                    type="submit"
                                    className="default-button"
                                >
                                    Be om tilbud
                                </button>

                            </div>
                        </div>}
                        {(selectedProduct.purchaseAbleOnWeb && selectedProduct.stock < 1 && selectedProduct.stockControlled) &&
                            <div className={"flex my-2"}>
                                <span className={"text-white bg-red-300 p-2 text-base font-medium"}>Utsolgt</span>
                            </div>}

                        {selectedProduct.productAttachments.length > 0 &&
                            <ProductAttachments attachments={selectedProduct.productAttachments}/>}

                    </div>
                </div>
                <ProductAdditionalDetailsView selectedProduct={selectedProduct}/>

                {productDetails.variants.length > 0 && getProductVariants(productDetails.variants)}
            </div>
        </div>)
    }
}


const useFetchProductDetails = (productId,userInfo,crmPerson) => {
    const [productDetails, setProductDetails] = useState({});
    useEffect(() => {
        fetchProductDetailsService(productId,userInfo,crmPerson).then(response => {
            if (response.status && response.status !== 200) console.error(response); else setProductDetails(response);
        }).catch(err => {
            console.error(err)
        })
    }, [productId,crmPerson])
    return {productDetails}

}

const mapStateToProps = ({userInfo,loggedInUser,crmPerson}) => {
    return {loggedInUser:loggedInUser,userInfo:userInfo,crmPerson}
}
export default connect(mapStateToProps, {addProductToCart,setProductForQuote})(ProductDetailsView)
